.description {
  background-color: white;
  padding-top: 200px;
  display: flex;
}

.description-title {
  font-family: obviously-wide, sans-serif;
  font-weight: 800;
  font-style: normal;
  color: white;
  font-size: 40px;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #df3737;

  @media (max-width: 768px) {
    font-size: 35px;
  }

  &.last {
    -webkit-text-stroke-color: #fff;
    color: #df3737;
  }
}

.description-container {
  display: flex;
  flex-direction: row;
  margin-top: 140px;
  margin-bottom: 160px;

  @media (max-width: 992px) {
    flex-direction: column;
  }
}

.description-arrow-container {
  display: flex;
  flex-direction: row;

  @media (max-width: 992px) {
    margin-bottom: 30px;
  }
}

.description-arrow {
  width: 80px;
  height: 90px;
  margin-right: 20px;
  transform: rotate(45deg);
}

.description-p {
  width: 450px;
  margin-left: 2vw;
  font-family: obviously-wide, sans-serif;
  font-weight: 800;
  font-style: normal;
  font-size: 24px;

  @media (max-width: 768px) {
    width: auto;
    font-size: 20px;
  }
}

.description-p-about {
  font-family: indivisible, sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 20px;
  margin-left: 0;
  width: auto;
}

.description-dog {
  @media (max-width: 768px) {
    margin-bottom: 100px;
  }
}

.description-container-about {
  justify-content: space-between;
}

.description-container-right {
  display: flex;
  align-items: self-start;
  flex-direction: column;
}

.description-container-row {
  display: flex;
  align-items: center;
}
