.presentation {
  background-color: #fff;
  padding-top: 150px;
}

.presentation-line {
  height: 1px;
  width: 100%;
  background-color: #df3737;
  margin: 10px 0 20px;
}

.presentation-title {
  font-weight: 700;
  margin-top: 100px;
}

.presentation-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  margin-top: 20px;
}

.presentation-mp4 {
  width: 100%;
  object-fit: cover;
  margin-top: 20px;
}

.presentation-img-container {
  height: 500px;
  overflow: hidden;
  position: relative;
}

.presentation-mp4-container {
  overflow: hidden;
  position: relative;
}

.presentation-img-full {
  width: 100%;
  margin-top: 140px;
  object-fit: contain;
}

.presentation-img-full-autoplay {
  width: 100%;
  height: 100%;
  margin-top: 140px;
  object-fit: contain;
}

.presentation-video {
  background-color: black;
}
