.scrolling-wrapper {
  margin-top: 5vh;
}

.scrolling-wrapper,
.scrolling-wrapper-down {
  overflow: hidden;
  white-space: nowrap;
  position: relative;
  font-family: obviously-wide, sans-serif;
  font-weight: 800;
  font-style: normal;
  font-size: max(min(8vw, 80px), 40px);
  line-height: normal;
  text-transform: uppercase;
}

.scrolling-content span,
.scrolling-content img {
  display: inline-block;
  vertical-align: middle;
}

.scrolling-content img {
  width: max(8vw, 60px);
  transform: translateY(25%);
}

.scrolling-wrapper-down {
  transform: translateY(max(-5vw, -60px));
}
