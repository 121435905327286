@import "bootstrap/scss/bootstrap-grid";
/* ====================================
// css variables
// ==================================== */

/* ====================================
// reset rules
// ==================================== */

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  font-size: 16px;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;

  font-size: 1rem;
  line-height: 1.25;
}

a {
  color: inherit;
}

img,
video {
  max-inline-size: 100%;
  block-size: auto;
  vertical-align: middle;
}

/* ====================================
// base rules
// ==================================== */

body {
  margin: 0;
}
canvas {
  display: block;
}
