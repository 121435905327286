body {
  background-color: #e4e3d2;
  color: #df3737;
}

html {
  scroll-behavior: smooth;
}

p {
  font-family: indivisible, sans-serif;
  font-weight: 500;
  font-style: normal;
}

h2 {
  font-family: indivisible, sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 24px;
  color: #df3737;
}

h1 a {
  text-decoration: none;
}
