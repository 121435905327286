.project {
  margin-top: 140px;
}

.project-container {
  margin-top: 70px;
  height: 140px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #df3737;

  @media (max-width: 768px) {
    margin-top: 30px;
  }
}

.project-name {
  font-family: obviously-condensed, sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 60px;

  @media (max-width: 768px) {
    font-size: 40px;
  }
}

.project-subject {
  font-size: 24px;

  @media (max-width: 768px) {
    font-size: 20px;
  }
}
