footer {
  background-color: #df3737;
  color: #fff;
  margin-top: 140px;
  padding: 70px 0 60px;
}

.footer-h2 {
  font-family: indivisible, sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 50px;
  color: #fff;

  @media (max-width: 768px) {
    font-size: 35px;
  }
}

.footer-title {
  font-family: indivisible, sans-serif;
  font-weight: 700;
  font-style: normal;
  margin: 5px 0;
  font-size: 20px;
}

.footer-media .footer-title {
  margin-top: 70px;
}

.footer-a {
  font-family: indivisible, sans-serif;
  font-weight: 500;
  font-style: normal;
  text-decoration: none;
  font-size: 16px;
  line-height: 25px;
}

.footer-media {
  display: flex;
  flex-direction: column;
}

.footer-about {
  margin-top: 0;
}
