header {
  overflow: hidden;
}

.header-nav {
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header-nav-h1 {
  font-size: 24px;
  font-family: obviously-wide, sans-serif;
  font-weight: 600;
  font-style: normal;
  line-height: normal;
}

.header-nav-burger-button-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 15px;
}

.header-nav-burger-button {
  width: 30px;
  height: 2px;
  background-color: #df3737;
}

.header-nav-content {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  transform: translateX(100%);
  transition: transform 0.3s ease;
  z-index: 30;
  display: flex;
  flex-direction: row;

  &.active {
    transform: translateX(0);
  }
}

.header-nav-content-left {
  width: 100%;
  height: 100%;
}

.header-nav-content-close-container {
  display: flex;
  justify-content: flex-end;
}

.header-nav-content-container {
  width: 35%;
  background-color: #df3737;
  color: #fff;
  right: 0;
  position: fixed;
  height: 100%;
  padding: 50px;

  @media (max-width: 768px) {
    width: 100%;
  }
}

.header-nav-content-close {
  width: 40px;
  height: 40px;
  border-radius: 100%;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.header-nav-content-close-line {
  width: 30px;
  height: 2px;
  background-color: #df3737;
  position: absolute;
  transform: rotate(45deg);

  &:last-child {
    transform: rotate(-45deg);
  }
}

.header-nav-content-h1 {
  font-family: indivisible, sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 20px;
  margin-top: 200px;

  @media (max-width: 768px) {
    margin-top: 100px;
  }
}

.header-nav-content-a-container {
  display: flex;
  flex-direction: column;
  margin-top: 30px;
}

.header-nav-content-a {
  font-family: obviously-condensed, sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 60px;
  text-decoration: none;
  margin-bottom: 20px;
}

.header-h1-container {
  position: relative;
  margin-top: 140px;
  white-space: nowrap;

  @media (max-width: 768px) {
    margin-top: 70px;
  }
}

.header-h1,
.header-h1-project {
  position: relative;
  font-family: obviously-wide, sans-serif;
  font-weight: 800;
  font-style: normal;
  text-transform: uppercase;
  font-size: calc(max(min(5vw, 70px), 25px));
  z-index: 10;
}

.header-h1-black {
  color: #373737;
}

.header-img {
  display: flex;
  align-items: center;
  overflow: hidden;
  & img {
    height: 155%;
    object-fit: cover;
    transform: translateY(75px);
  }
}

.header-description {
  margin-top: 55px;
  display: flex;
  @media (max-width: 576px) {
    flex-direction: column;
  }
}

.header-description-arrow-container {
  position: relative;
  width: 25%;
  @media (max-width: 576px) {
    height: 6vh;
  }
}

.header-description-arrow {
  width: min(10vw, 80px);
  height: min(10vw, 80px);
}

.header-description-arrow-main {
  opacity: 0;
  width: min(10vw, 80px);
  height: min(10vw, 80px);
  animation: arrowAnimation 3s infinite ease-in-out;
  position: absolute;
  right: 80px;
  @media (max-width: 576px) {
    left: 0;
  }
  &:last-child {
    animation-delay: 1.5s;
  }
}

.header-description-p {
  width: 300px;
  margin-left: 2vw;
  @media (max-width: 576px) {
    width: 100%;
    margin-left: 0;
  }
}

.header-description-about {
  flex-direction: column;
  justify-content: flex-end;
}

.header-description-arrow-about {
  transform: rotate(45deg);
  width: 7vw;
  height: 100%;
}

.header-description-p-about {
  margin-left: 0;
}

.header-img-about {
  margin-top: 30px;
}

.header-description-project {
  @media (min-width: 576px) {
    margin-bottom: 50px;
  }
}

.header-description-img-project {
  height: 700px;
  object-fit: cover;
}

.header-description-title-project {
  font-weight: 700;
  margin-top: 100px;
}

.header-description-subject-project .header-description-title-project {
  @media (max-width: 768px) {
    margin-top: 50px;
  }
}

.header-description-line-project {
  height: 1px;
  width: 100%;
  background-color: #df3737;
  margin: 10px 0 20px;
}

.header-description-arrow-container-project {
  width: auto;
}

.header-description-arrow-project {
  transform: rotate(45deg);
}

.header-description-link-container-project {
  margin-top: 30px;
}

.header-description-link-project {
  padding: 10px 15px;
  background-color: #df3737;
  color: #fff;
  font-family: indivisible, sans-serif;
  font-weight: 500;
  font-style: normal;
  border-radius: 15px;
  text-decoration: none;
}

.header-h1:nth-child(1) {
  left: 44%;
  display: flex;
  align-items: baseline;
}

.header-h1:nth-child(3) {
  left: 10%;
}

.header-h1:nth-child(4) {
  transform: translateX(22.5%);
}

.header-h1:nth-child(5) {
  transform: translateX(38.5%);
}

.header-h1-img {
  width: 7vw;
  margin-right: 1vw;
}

@keyframes arrowAnimation {
  0% {
    transform: translateX(0);
    opacity: 0;
  }
  25% {
    transform: translateX(0);
    opacity: 100%;
  }
  50% {
    transform: translateX(100%);
    opacity: 100%;
  }
  75% {
    transform: translateX(100%);
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

@media (max-width: 576px) {
  @keyframes arrowAnimation {
    0% {
      transform: translateX(0) rotate(45deg);
      opacity: 0;
    }
    25% {
      transform: translateX(0) rotate(45deg);
      opacity: 100%;
    }
    50% {
      transform: translateX(100%) rotate(45deg);
      opacity: 100%;
    }
    75% {
      transform: translateX(100%) rotate(45deg);
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
}
